import { useCallback } from 'react';

import { trackAnalyticsEvent } from './analytics';

/**
 * Hook that returns a callback that will open the Qualified chat
 */
const useQualified = () => {
  return useCallback(() => {
    if (window.qualified) {
      window.qualified('open');
      trackAnalyticsEvent('talkToSales', { value: 'openQualified' });
    } else {
      trackAnalyticsEvent('talkToSales', { value: 'routeToFallback' });
      window.location.href = '/company/contact-sales';
    }
  }, []);
};

/**
 * Hook that simply alerts 'debug'
 */
const useDebug = () => {
  return useCallback(() => alert('debug'), []);
};

const interactionHooks = {
  openIntercom: useQualified, // Can be deleted when Sanity is updated
  openChat: useQualified,
  debug: useDebug,
};

export default interactionHooks;
