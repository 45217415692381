import React, { forwardRef, useId } from 'react';
import cn from 'classnames';

import BaseToggleInput from './ToggleInput';

import Tooltip from '@components/Tooltip';

import inputStyles from '@styles/inputs.module.css';
import * as styles from './Inputs.module.css';
import visuallyHidden from '@styles/utilities/visuallyHidden.module.css';
import { honeypotField } from './constants';

const withLabelAndHint = (Component) => {
  // We're ok with not passing a display name to this HOC
  // eslint-disable-next-line
  return forwardRef(
    (
      {
        className,
        inputClassName,
        label,
        labelVariant,
        hint,
        errors,
        tooltip,
        ...props
      },
      ref,
    ) => {
      const inputId = useId();
      const hintId = useId();

      return (
        <div className={cn(styles.root, className)}>
          {label && (
            <div className={cn(styles.labelWrapper)}>
              <label
                className={cn(inputStyles.label)}
                data-variant={labelVariant}
                htmlFor={inputId}
              >
                {label}
              </label>
              {tooltip && (
                <Tooltip className={cn(styles.tooltip)} text={tooltip} />
              )}
            </div>
          )}
          <div className={cn(styles.inputWrapper)}>
            <Component
              {...props}
              className={inputClassName}
              id={inputId}
              inputRef={ref}
              aria-invalid={errors ? 'true' : null}
              aria-describedby={hint ? hintId : null}
            />
          </div>
          {hint && !errors && (
            <div className={cn(styles.hint)} id={hintId}>
              {hint}
            </div>
          )}
          {errors && (
            <div className={cn(styles.error)} role="alert">
              {errors.message}
            </div>
          )}
        </div>
      );
    },
  );
};

const withCheckboxLabelAndHint = (Component) => {
  // We're ok with not passing a display name to this HOC
  // eslint-disable-next-line
  return forwardRef(
    ({ className, inputClassName, label, hint, errors, ...props }, ref) => {
      const inputId = useId();
      const hintId = useId();

      return (
        <div className={cn(styles.root, className)}>
          <div className={cn(styles.inlineLabelAndInput)}>
            <div className={cn(styles.inputWrapper)}>
              <Component
                {...props}
                className={inputClassName}
                id={inputId}
                inputRef={ref}
                aria-invalid={errors ? 'true' : null}
                aria-describedby={hint ? hintId : null}
              />
            </div>
            <label className={styles.checkboxLabel} htmlFor={inputId}>
              {label}
            </label>
          </div>
          {hint && !errors && (
            <div className={cn(styles.hint)} id={hintId}>
              {hint}
            </div>
          )}
          {errors && (
            <div className={cn(styles.error)} role="alert">
              {errors.message}
            </div>
          )}
        </div>
      );
    },
  );
};

export const TextInput = withLabelAndHint(
  ({ className, inputRef, type = 'text', multiline = false, ...props }) => {
    return multiline ? (
      <textarea
        {...props}
        className={cn(inputStyles.textarea, className)}
        ref={inputRef}
      />
    ) : (
      <input
        type={type}
        {...props}
        className={cn(inputStyles.input, className)}
        ref={inputRef}
      />
    );
  },
);

export const CheckboxInput = withCheckboxLabelAndHint(
  ({ className, inputRef, ...props }) => {
    return (
      <input
        type="checkbox"
        {...props}
        className={cn(inputStyles.checkbox, className)}
        ref={inputRef}
      />
    );
  },
);

export const NumberInput = withLabelAndHint(
  ({
    className,
    inputRef,
    slider = false,
    value,
    formatValue = (x) => x,
    ...props
  }) => {
    return slider ? (
      <div className={styles.sliderWrapper}>
        <span className={styles.sliderValue}>{formatValue(value)}</span>
        <input
          type="range"
          {...props}
          className={cn(inputStyles.slider, styles.slider, className)}
          ref={inputRef}
          value={value}
        />
      </div>
    ) : (
      <input
        type="number"
        {...props}
        className={cn(inputStyles.input, className)}
        ref={inputRef}
        value={value}
      />
    );
  },
);

export const ToggleInput = withLabelAndHint(
  ({ value, className, ...props }) => {
    return (
      <BaseToggleInput
        className={cn(styles.toggle, className)}
        value={value}
        {...props}
      />
    );
  },
);

export const HoneyPotInput = ({ register }) => {
  return (
    <input
      {...register(honeypotField)}
      className={visuallyHidden.root}
      tabIndex="-1"
      autoComplete="false"
    />
  );
};

ToggleInput.Option = BaseToggleInput.Option;
