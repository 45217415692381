export const isSafari = () => {
  if (typeof navigator === 'undefined') return false;

  const uaString = navigator.userAgent.toLowerCase();
  return uaString.includes('safari') && !uaString.includes('chrome');
};

export const isBrowser = () => {
  return typeof window !== 'undefined';
};
