import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useInView } from 'framer-motion';

import * as styles from './TextRenderer.module.css';

const TextRenderer = ({ className, text, as = 'div' }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: 0.9, once: true });
  const Tag = as;

  return (
    <Tag
      ref={ref}
      className={cn(styles.root, className, { [styles.inView]: inView })}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

TextRenderer.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  as: PropTypes.string,
};

export default TextRenderer;
