import React from 'react';
import cn from 'classnames';

import * as styles from './ToggleInput.module.css';

const ToggleInput = ({ className, children }) => {
  return <div className={cn(styles.root, className)}>{children}</div>;
};

ToggleInput.Option = React.forwardRef(
  ({ label, subtitle, value, ...rest }, ref) => {
    return (
      <label className={styles.item}>
        <input
          className={styles.input}
          type="radio"
          ref={ref}
          value={value}
          {...rest}
        />
        <div className={styles.clickable}>
          <div className={styles.label}>{label}</div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      </label>
    );
  },
);

ToggleInput.Option.displayName = 'ToggleInput.Option';

export default ToggleInput;
