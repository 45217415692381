import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useInView } from 'framer-motion';

import * as styles from './Marquee.module.css';

const Marquee = ({
  children,
  speed = 1,
  reverse = false,
  shouldPlay = true,
  onlyPlayInView = true,
  style,
  onPointerEnter,
  onPointerLeave,
}) => {
  /* The repeat count makes sure the marquee can be animated infinitely.
   * So two is the lowest possible repeat count.
   *
   * This is hardcoded at two right now, because all marquees in use currently
   * have enough content to achieve the illusion of infinite animation by only
   * using one copy of the content.
   *
   * If this ever becomes an issue for marquees with less content a dynamic
   * calculation of the repeat count based on window and item sizes can be
   * used to fix it.
   */
  const repeatCount = 2;
  const ref = useRef();
  const isInView = useInView(ref, { amount: 'some' });

  const animationDuration = useMemo(
    () => (4 / speed) * repeatCount,
    [speed, repeatCount],
  );

  useEffect(() => {
    if (!onlyPlayInView) {
      ref.current.classList.add(styles.inView);
      return;
    }
    if (isInView && shouldPlay) {
      ref.current.classList.add(styles.inView);
    } else {
      ref.current.classList.remove(styles.inView);
    }
  }, [isInView, shouldPlay, onlyPlayInView]);

  useEffect(() => {
    if (!shouldPlay) {
      ref.current.classList.add(styles.paused);
    } else {
      ref.current.classList.remove(styles.paused);
    }
  }, [shouldPlay]);

  const Inner = (i = 0) => {
    return React.Children.map(children, (child, j) => {
      return (
        <div
          key={`${i}-${j}`}
          className={styles.item}
          aria-hidden={i > 0 ? true : null}
        >
          {child}
        </div>
      );
    });
  };

  return (
    <div
      ref={ref}
      className={cn(styles.root, {
        [styles.reverse]: reverse,
      })}
      style={{
        ...style,
        '--marquee-speed': `${animationDuration}s`,
      }}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {isInView || !onlyPlayInView ? (
        <div className={styles.itemWrapper}>
          {Array.from({ length: repeatCount }).map((_, i) => {
            return Inner(i);
          })}
        </div>
      ) : (
        <div className={styles.itemWrapper}>{Inner()}</div>
      )}
    </div>
  );
};

Marquee.propTypes = {
  children: PropTypes.node,
  speed: PropTypes.number,
  reverse: PropTypes.bool,
  shouldPlay: PropTypes.bool,
  onlyPlayInView: PropTypes.bool,
  style: PropTypes.object,
};

export default Marquee;
