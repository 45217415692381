import React from 'react';
import cn from 'classnames';

import { colorMap } from '@constants/colors';

import Image from '@components/Image';
import Illustration from '@components/Illustration';
import TickList from '@components/TickList';
import TextRenderer from '@components/TextRenderer';

import SimpleGradient from './SimpleGradient';

import measureStyles from '@styles/utilities/measures.module.css';
import textStyles from '@styles/textStyles.module.css';

import * as styles from './PrimaryHero.module.css';
import { ClientLogoMarquee } from '@components/Marquee';
import ActionButtons from './ActionButtons';

const Media = ({ media, className }) => {
  return <figure className={className}>{media}</figure>;
};

export const BasePrimaryHero = React.forwardRef(
  (
    {
      actions = [],
      backgroundIllustration,
      children,
      className,
      clientLogos = [],
      eyebrow,
      flipGradient = false,
      gradient,
      heading,
      headingSize = 'large',
      markerColor,
      media,
      mediaClassName,
      mediaOverlap = true,
      noBackground = false,
      style,
      text,
      theme = 'light',
      verticalAlign = 'default',
      trackingScope = 'PrimaryHero',
    },
    ref,
  ) => {
    const HeadlineTag = 'h2';

    const rootClassNames = cn(
      styles.root,
      {
        [styles.themeLight]: theme === 'light',
        [styles.themeDark]: theme === 'dark',
        [styles.flipGradient]: flipGradient,
        [styles.headingSizeSmall]: headingSize === 'small',
        [styles.headingSizeXLarge]: headingSize === 'xlarge',
        [styles.noChildren]: !children,
        [styles.hasEyebrow]: eyebrow,
        [styles.hasMediaOverlap]: mediaOverlap,
        [styles.verticalAlignCenter]: verticalAlign === 'center',
        [styles.noBackground]: noBackground,
      },
      className,
    );

    return (
      <section
        ref={ref}
        className={rootClassNames}
        style={{
          '--section-marker-color': markerColor && colorMap[markerColor],
          ...style,
        }}
      >
        <div className={styles.headerContainer}>
          {gradient && !backgroundIllustration && (
            <div className={styles.gradient} aria-hidden>
              <SimpleGradient fill={gradient} />
            </div>
          )}

          {backgroundIllustration && (
            <div className={styles.backgroundIllustration} aria-hidden>
              <Illustration illustration={backgroundIllustration} />
            </div>
          )}

          <div className={styles.header}>
            <div className={styles.headerInner}>
              <div>
                {eyebrow && <p className={cn(textStyles.eyebrow)}>{eyebrow}</p>}
                {typeof heading === 'string' ? (
                  <TextRenderer
                    text={heading}
                    as={HeadlineTag}
                    className={cn(styles.headline, textStyles.headlineXL)}
                  />
                ) : (
                  <HeadlineTag
                    className={cn(styles.headline, textStyles.headlineXL)}
                  >
                    {heading}
                  </HeadlineTag>
                )}
                {typeof text === 'string' ? (
                  <p
                    className={
                      (textStyles.bodyM, measureStyles.medium, styles.intro)
                    }
                  >
                    {text}
                  </p>
                ) : (
                  text
                )}

                {Array.isArray(actions) && actions.length > 0 && (
                  <ActionButtons
                    actions={actions}
                    theme={theme}
                    trackingScope={trackingScope}
                  />
                )}
              </div>
              {children && (
                <div
                  aria-hidden={!!children || null}
                  className={styles.children}
                >
                  {children}
                </div>
              )}
            </div>
            {!mediaOverlap && media && (
              <div className={styles.headerMedia}>
                <Media media={media} className={mediaClassName} />
              </div>
            )}
          </div>
          {Array.isArray(clientLogos) && clientLogos.length > 0 && (
            <aside className={styles.clientLogos}>
              <h3 className={textStyles.eyebrow}>
                Trusted by industry leaders
              </h3>
              <ClientLogoMarquee clientLogos={clientLogos} speed={0.1} />
            </aside>
          )}
        </div>
        {mediaOverlap && media && (
          <Media
            media={media}
            className={cn(styles.mediaOverlap, mediaClassName)}
          />
        )}
      </section>
    );
  },
);

BasePrimaryHero.displayName = 'BasePrimaryHero';

export const PrimaryHeroWithIllustration = ({
  eyebrow,
  flipGradient,
  gradient,
  heading,
  illustration,
  listItems,
  markerColor,
  mediaOverlap = true,
  text,
  theme = 'light',
}) => {
  return (
    <BasePrimaryHero
      eyebrow={eyebrow}
      flipGradient={flipGradient}
      gradient={gradient}
      heading={heading}
      illustration={illustration}
      listItems={listItems}
      markerColor={markerColor}
      media={<Illustration illustration={illustration} />}
      mediaClassName={styles.preprocessMedia}
      mediaOverlap={mediaOverlap}
      style={{
        '--hero-media-aspect-ratio': '16 / 9',
      }}
      text={text}
      theme={theme}
    >
      {text && (
        <p className={(textStyles.bodyM, measureStyles.medium, styles.intro)}>
          {text}
        </p>
      )}
      {listItems && (
        <TickList
          className={cn(styles.list)}
          items={listItems}
          style={{ '--tick-icon-color': 'currentColor' }}
        />
      )}
    </BasePrimaryHero>
  );
};

export const PrimaryHeroWithImage = ({
  eyebrow,
  flipGradient,
  gradient,
  heading,
  image,
  listItems,
  markerColor,
  mediaOverlap = true,
  text,
  theme,
}) => {
  return (
    <BasePrimaryHero
      eyebrow={eyebrow}
      flipGradient={flipGradient}
      gradient={gradient}
      heading={heading}
      listItems={listItems}
      markerColor={markerColor}
      mediaClassName={styles.preprocessMedia}
      mediaOverlap={mediaOverlap}
      media={
        <div className={styles.imageWrapper}>
          <Image src={image.src} alt={image.alt} fill sizes="100vw" />
        </div>
      }
      style={{
        '--hero-media-aspect-ratio': '16 / 9',
      }}
      text={text}
      theme={theme}
    >
      {text && (
        <p className={(textStyles.bodyM, measureStyles.medium, styles.intro)}>
          {text}
        </p>
      )}
      {listItems && <TickList className={cn(styles.list)} items={listItems} />}
    </BasePrimaryHero>
  );
};
