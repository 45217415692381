import Button from '@components/Button';
import interactionHooks from '@util/interactions';
import * as styles from './ActionButtons.module.css';

const ActionButtons = ({ actions, theme, trackingScope }) => {
  return (
    <div className={styles.root}>
      {actions.map((action, i) => {
        const interaction = action.interaction
          ? interactionHooks[action.interaction]()
          : null;
        return (
          <div key={i} className={styles.action}>
            <Button
              fullWidth
              colorScheme={theme === 'light' ? 'accent' : 'light'}
              size="large"
              withArrow
              variant={action.type}
              href={action.href}
              onClick={interaction}
              trackingScope={trackingScope}
              isStartFreeTrial={action.isStartFreeTrial ?? null}
            >
              {action.label}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default ActionButtons;
