import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tick from '@assets/icons/Tick.svg';

import * as styles from './TickList.module.css';
import textStyles from '@styles/textStyles.module.css';

const TickList = ({
  items = [],
  className,
  style,
  variant = 'unobtrusive',
  size = 'small',
}) => {
  return (
    <ul
      className={cn(styles.root, className)}
      style={style}
      data-variant={variant}
    >
      {items.map((item, i) => (
        <TickListItem hasCheck key={`item-${i}`} size={size}>
          {item}
        </TickListItem>
      ))}
    </ul>
  );
};

TickList.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  styles: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['unobtrusive', 'primary']),
};

export const TickListItem = ({ children, hasCheck = true, size = 'small' }) => {
  return (
    <li
      className={cn(styles.listItem, {
        [textStyles.bodyS]: size === 'small',
        [textStyles.bodyM]: size === 'medium',
        [textStyles.bodyL]: size === 'large',
      })}
    >
      {hasCheck && <Tick className={styles.tick} />}
      <span>{children}</span>
    </li>
  );
};

export default TickList;
